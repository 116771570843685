import React, { useState, useEffect } from "react";
import { Routes, Route, NavLink } from "react-router-dom";

import LOCATION_TYPES from './../classes/LOCATION_TYPES';
import EmployeeDetails from './../components/EmployeeDetails';
import Loading from './../components/Loading';
import Tooltipp from './../components/Tooltipp';

import API from '../classes/API';

export default function Reporting({sessionToken, reportList, teams}) {

    const formatDate = ( date ) => {
        date = date.split("-").reverse();
        return date.join(".");
    }

    return (
        <div>
            <Routes>    
                <Route path="/" element={<Reports reportList={reportList} />} />
                <Route path="/resturlaub" element={<VacationOverview sessionToken={sessionToken} formatDate={formatDate} /> } />
                <Route path="/genehmigte-antraege" element={<ApprovedRequests sessionToken={sessionToken} formatDate={formatDate} /> } />
            </Routes>
        </div>
    )
}

function Reports({reportList}) {

    const _formatURL = ( value ) => {
        value = value.toLowerCase().replace(/ /g,'-');
        value = value.replace(/ä/g, 'ae');
        value = value.replace(/ö/g, 'oe');
        value = value.replace(/ü/g, 'ue');
        return value;
    }

    if( reportList === null || reportList.length <= 0 ) {
        return(
            <div>
                <p>Kein Reporting vorhanden.</p>
            </div>
        )
    } else {
        return(
            <div className="we-report-dashboard">
                <h2>Report</h2>
                <p>Wähle einen Report aus</p>
                <ul>{reportList.map(report => <li><NavLink to={`${process.env.PUBLIC_URL}/reporting/${_formatURL(report.name)}`}><b>Report</b>{report.name}</NavLink><span><svg width="13px" height="14px" viewBox="0 0 13 14" version="1.1" xmlns="http://www.w3.org/2000/svg"><g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round"><g transform="translate(-359.000000, -318.000000)" stroke="#1F1F24" strokeWidth="2"><g><g transform="translate(18.000000, 240.000000)"><g transform="translate(328.507000, 66.507000)"><g transform="translate(13.090000, 13.090000)"><line x1="0.403" y1="5.403" x2="10.403" y2="5.403" id="Linie_41" strokeLinejoin="round"></line><polyline id="Pfad_35" points="5.403 0 10.803 5.4 5.403 10.8"></polyline></g></g></g></g></g></g></svg></span></li>)}</ul>
            </div>
        )
    }
    
}

function ApprovedRequests({sessionToken,formatDate}) {
    const [requests, setRequests] = useState( null );
    const [isLoading, setIsLoading] = useState( true );
    const [teamIDFilter, setTeamIDFilter] = useState( "" );
    const [selectedYear, setSelectedYear] = useState( new Date().getFullYear()  );


    useEffect(function(){
        API.getVacationRequests(sessionToken, selectedYear).then(response => {
            if( response.success ) {
                setRequests( response.requests, setIsLoading( false ) );
            }else {
                setRequests( [], setIsLoading( false ) );
            }
        });
        
    },[sessionToken, selectedYear]);

    if( isLoading || requests === null ) {
        return <Loading />;
    }

    return(
        <div className="we-report-vacation">
            {/*<EmployeeFilter API={API} sessionToken={sessionToken} teamIDFilter={teamIDFilter} setTeamIDFilter={setTeamIDFilter} />*/}
            <h2>Report</h2>
            <div className="we-report-vacation__wrapper">
                <table>
                    <thead>
                        <tr>
                            <th>
                                <div className="we-select__wrapper">
                                    <select 
                                        className="we-select" 
                                        onChange={(e) => setSelectedYear(e.target.value)}
                                        value={selectedYear}
                                    >
                                        <option key={`FILTER_OPTION_0`} value="2020" >2020</option>
                                        <option key={`FILTER_OPTION_1`} value="2021" >2021</option>
                                        <option key={`FILTER_OPTION_2`} value="2022" >2022</option>
                                        <option key={`FILTER_OPTION_3`} value="2023" >2023</option>
                                        <option key={`FILTER_OPTION_4`} value="2024" >2024</option>
                                        <option key={`FILTER_OPTION_5`} value="2025" >2025</option>
                                    </select>
                                    <span className="we-select__arrow">
                                        <svg width="12px" height="8px" viewBox="0 0 12 8" version="1.1" xmlns="http://www.w3.org/2000/svg">
                                            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round">
                                                <g transform="translate(-212.000000, -257.000000)" stroke="#FFFFFF" strokeWidth="2">
                                                    <g>
                                                        <g transform="translate(18.000000, 240.000000)">
                                                            <g transform="translate(200.000000, 20.000000) rotate(90.000000) translate(-200.000000, -20.000000) translate(189.000000, 9.000000)">
                                                                <polyline transform="translate(12.000000, 11.000000) rotate(-90.000000) translate(-12.000000, -11.000000) " points="7.712 8.856 12 13.144 16.288 8.856"></polyline>
                                                            </g>
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                        </svg>
                                    </span>
                                </div>
                            </th>
                            <th>Antragsdatum</th>
                            <th>Start- / Enddatum</th>
                            <th>Anspruch Vorjahr</th>
                            <th>Anspruch Jahresurlaub</th>
                            <th>&nbsp;</th>
                        </tr>
                    </thead>
                    <tbody>
                    {requests.map((request, index) => 
                        <tr key={`REQUEST_${index}`}>
                            <td><EmployeeDetails employee={request.user} isSmall="true" /><Tooltipp><p className="h3">Freigegeben durch:</p>{request.approvals.map(user => <><span>{user.firstname} {user.lastname} <small>({formatDate(user.date)})</small></span></>)}</Tooltipp></td>
                            <td>{formatDate(request.request_date.split(' ')[0])}</td>
                            {request.days.length > 1 ?
                            <td>{formatDate(request.days[0])} - {formatDate(request.days[(request.days.length - 1)])}</td>
                            : <td>{formatDate(request.days[0])}</td>}
                            
                            <td>{request.factorLastYear}</td>
                            <td>{request.factor}</td>
                            <td>{LOCATION_TYPES[request.type].label}</td>
                        </tr>
                    )}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

function VacationOverview({sessionToken}) {
    const [employees, setEmployees] = useState( null );
    const [teamIDFilter, setTeamIDFilter] = useState( "" );
    const [isLoading, setIsLoading] = useState( true );
    const [selectedYear, setSelectedYear] = useState( new Date().getFullYear()  );

    useEffect(() => {
        API.getVacationOverview(sessionToken, selectedYear).then( response => {
            if( response.success ) {
                setEmployees(response.users.sort((a,b) => (a.lastYearLeft < b.lastYearLeft) ? 1 : ((b.lastYearLeft < a.lastYearLeft) ? -1 : 0)), setIsLoading( false ) );
            } else {
                setEmployees(response.users, setIsLoading( false ) );
            }
        });
    }, [sessionToken,selectedYear]);


    if( isLoading || employees === null ) {
        return <Loading />;
    }

    if( !employees || employees.length <= 0 ) {
        return (
            <div>
                <p>Keine Daten gefunden.</p>
            </div>
        )
    } else {

        if( window.matchMedia("(min-width: 40.063em)").matches ) {
            return(
                <div className="we-report-vacation">
                    <h2>Report</h2>
                    {/*<EmployeeFilter API={API} sessionToken={sessionToken} teamIDFilter={teamIDFilter} setTeamIDFilter={setTeamIDFilter} />*/}
                    <div className="we-report-vacation__wrapper">
                        <table>
                            <thead>
                                <tr>
                                    <th>
                                        <div className="we-select__wrapper">
                                            <select 
                                                className="we-select" 
                                                onChange={(e) => setSelectedYear(e.target.value)}
                                                value={selectedYear}
                                            >
                                                <option key={`FILTER_OPTION_0`} value="2020" >2020</option>
                                                <option key={`FILTER_OPTION_1`} value="2021" >2021</option>
                                                <option key={`FILTER_OPTION_2`} value="2022" >2022</option>
                                                <option key={`FILTER_OPTION_3`} value="2023" >2023</option>
                                                <option key={`FILTER_OPTION_4`} value="2024" >2024</option>
                                                <option key={`FILTER_OPTION_5`} value="2025" >2025</option>
                                            </select>
                                            <span className="we-select__arrow">
                                                <svg width="12px" height="8px" viewBox="0 0 12 8" version="1.1" xmlns="http://www.w3.org/2000/svg">
                                                    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round">
                                                        <g transform="translate(-212.000000, -257.000000)" stroke="#FFFFFF" strokeWidth="2">
                                                            <g>
                                                                <g transform="translate(18.000000, 240.000000)">
                                                                    <g transform="translate(200.000000, 20.000000) rotate(90.000000) translate(-200.000000, -20.000000) translate(189.000000, 9.000000)">
                                                                        <polyline transform="translate(12.000000, 11.000000) rotate(-90.000000) translate(-12.000000, -11.000000) " points="7.712 8.856 12 13.144 16.288 8.856"></polyline>
                                                                    </g>
                                                                </g>
                                                            </g>
                                                        </g>
                                                    </g>
                                                </svg>
                                            </span>
                                        </div>
                                    </th>
                                    <th>Rest Vorjahr</th>
                                    <th>Jahresanspruch</th>
                                    <th>Anspruch gesamt</th>
                                    <th>Genehmigt</th>
                                    <th>Unbestätigt</th>
                                    <th>Resturlaub</th>
                                    <th>Rest Arbeitstage</th>
                                </tr>
                            </thead>
                            <tbody>
                                {employees.map(employee =>
                                    <tr key={employee.id} className={employee.status !== null ? employee.status : ""}>
                                        <td><EmployeeDetails employee={employee} className="we-report-column" isSmall="true" /></td>
                                        <td className={`we-report-column${(employee.lastYearLeft || employee.lastYearLeft !== 0) ? " warning" : ""}`}>{(employee.lastYearLeft || employee.lastYearLeft !== 0) ? employee.lastYearLeft : "-"}</td>
                                        <td className="we-report-column">{(employee.vacationClaim || employee.vacationClaim !== 0) ? employee.vacationClaim : "-"}</td>
                                        <td className="we-report-column">{( employee.lastYearLeft + employee.vacationClaim ) || ( employee.lastYearLeft + employee.vacationClaim ) !== 0 ? ( employee.lastYearLeft + employee.vacationClaim ) : "-"}</td>
                                        <td className="we-report-column">{(employee.claimTaken || employee.claimTaken !== 0) ? employee.claimTaken : "-"}</td>
                                        <td className="we-report-column">{(employee.claimPending || employee.claimPending !== 0) ? employee.claimPending : "-"}</td>
                                        <td className="we-report-column">{(employee.claimLeft || employee.lastYearLeft ) ? ( employee.claimLeft + employee.lastYearLeft ) : "-" }</td>
                                        <td className="we-report-column">{(employee.daysLeft || employee.daysLeft !== 0) ? employee.daysLeft : "-"}</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            )
        } else {
            return (
                <div className="we-report-vacation">
                    <h2>Report</h2>
                    {employees.map(employee =>
                        <div className="we-report-card">
                            <div className="we-report-card__information">
                                {(employee.img && employee.img !== "" ) ? 
                                    <img className="we-report-card__img" src={`data:image/png;base64,${employee.img}`} alt="" title="" />
                                    : <span className="we-report-card__img">{employee.firstname.charAt(0)+employee.lastname.charAt(0)}</span>
                                }
                                <p className="we-report-card__name">{employee.firstname ? employee.firstname : ""} {employee.lastname ? employee.lastname : "" }{employee.nickname ? <small></small> : ""}</p>
                                <ul className="we-report-card__actions">
                                    {employee.email !== "" ? <li><a href={`mailto:${employee.email}`} title=""><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-mail"><path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path><polyline points="22,6 12,13 2,6"></polyline></svg></a></li> : ""}
                                    {employee.phone !== "" ? <li><a href={`tel:${employee.phone}`} title=""><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-phone"><path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"></path></svg></a></li> : ""}
                                </ul>
                            </div>
                            <hr />
                            <dl>
                                <dt>REST VORJAHR</dt>
                                <dd className={`${(employee.lastYearLeft || employee.lastYearLeft !== 0) ? " warning" : ""}`}>{(employee.lastYearLeft || employee.lastYearLeft !== 0) ? employee.lastYearLeft : "-"}</dd>
                                <dt>REST URLAUB</dt>
                                <dd>{(employee.claimLeft || employee.lastYearLeft ) ? ( employee.claimLeft + employee.lastYearLeft ) : "-" }</dd>
                                <dt>REST ARBEITSTAGE</dt>
                                <dd>{(employee.daysLeft || employee.daysLeft !== 0) ? employee.daysLeft : "-"}</dd>
                            </dl>
                        </div>
                    )}
                </div>
            )
        }
    }

    
}
